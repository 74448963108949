<template>
  <div class="roleMain">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="hengBox"></div> -->

    <div class="box center justify">
      <el-form :inline="true" class="box center" :model="ruleForm" ref="ruleForm">
        <el-form-item prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入管理员姓名/账号"></el-input>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input v-model="ruleForm.companyName" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item prop="adminCompany">
          <el-button @click="handleCurrentChange(1)">查询</el-button>
          <el-button @click="resetData">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button @click="jumpAdd('')" :class="!myUpload ? 'disable_icon' : ''">新建管理员</el-button>
    </div>
    <el-table class="mT30" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" prop="username" label="账号"></el-table-column>
      <el-table-column align="center" prop="realname" label="姓名"></el-table-column>
      <el-table-column align="center" prop="companyName" label="所属单位"></el-table-column>
      <el-table-column align="center" prop="roleName" label="角色"></el-table-column>
      <el-table-column align="center" width="240" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" min-width="54" prop="id" label="操作">
        <template slot-scope="scope">
          <!-- <svg @click="jumpAdd(scope.row.id)" class="icon fs22 operate-txt" aria-hidden="true">
            <use xlink:href="#iconbianji"></use>
          </svg>
          <svg @click="deleteRole(scope.row.id)" class="icon fs22 operate-txt ml24" aria-hidden="true">
            <use xlink:href="#iconshanchu"></use>
          </svg>
          <svg @click="alertPwd(scope.row)" class="icon fs22 operate-txt ml24" aria-hidden="true">
            <use xlink:href="#iconxiugaimima"></use>
          </svg> -->
          <span type="text" class="colorText mR20 pointer" size="small" @click="jumpAdd(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">修改</span>
          <span type="text" class="colorText mR20 pointer" size="small" @click="deleteRole(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>

          <span type="text" class="colorText pointer" size="small" @click="alertPwd(scope.row)" :class="!myOption ? 'disable_icon' : ''">修改密码</span>
        </template>
      </el-table-column>
      <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template>
    </el-table>
    <common-pagination class="pageMain" :total="total" :pageSize="pageSize" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    <el-dialog title="重置密码" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
      <el-form :model="ruleFormPwd" ref="ruleFormPwd" :rules="rules" label-width="180px">
        <el-form-item label="用户名" prop="title">
          <span style="color: #fff">{{ seledItem.username }}</span>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="ruleFormPwd.password" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input type="password" placeholder="请再次输入密码" v-model="ruleFormPwd.confirmPwd" />
        </el-form-item>
        <el-form-item class="btns mT20">
          <el-button class="btn back-btn" @click="handleClose">取消</el-button>
          <el-button class="btn submit-btn" @click="submitForm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
export default {
  name: "roleList",
  components: {
    CommonPagination
  },
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      ruleForm: { name: "", companyName: "" },
      ruleFormPwd: { password: "", confirmPwd: "" },
      dialogVisible: false,
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 18, message: "6-18位英文/数字/字符组成", trigger: "blur" }
        ],
        confirmPwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { min: 6, max: 18, message: "6-18位英文/数字/字符组成", trigger: "blur" }
        ]
      },
      seledItem: {}
    };
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },
    submitForm() {
      this.$refs.ruleFormPwd.validate((valid) => {
        if (valid) {
          if (this.ruleFormPwd.password !== this.ruleFormPwd.confirmPwd) {
            this.$message.error("两次输入密码不一致");
          } else {
            this.httpAlertPwd();
          }
        }
      });
    },
    alertPwd(item) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.seledItem = item;
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.ruleFormPwd.resetFields();
      this.dialogVisible = false;
    },
    resetData() {
      this.$refs.ruleForm.resetFields();
      this.handleCurrentChange(1);
    },
    jumpAdd(id) {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }

      this.$router.push({
        name: "addAdmin",
        query: {
          id
        }
      });
    },
    deleteRole(id) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$confirm(`确认删除？`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.httpDel(id);
      });
    },
    httpAlertPwd() {
      let params = {
        password: this.ruleFormPwd.password
      };
      this.$axios.put(`${this.baseURL}auth/authUser/password/${this.seledItem.id}`, this.$qs.stringify(params)).then((res) => {
        if (res.data.errCode == 200) {
          this.$message({
            message: "密码修改成功",
            type: "success",
            duration: 1000
          });
          this.handleClose();
          this.handleCurrentChange(1);
        }
      });
    },
    httpDel(id) {
      this.$axios.delete(`${this.baseURL}auth/authUser/${id}`).then((res) => {
        if (res.data.errCode == 200) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 1000
          });
          this.handleCurrentChange(1);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableList();
    },
    getTableList() {
      const params = {
        companyName: this.ruleForm.companyName,
        name: this.ruleForm.name
      };
      this.$axios.get(`${this.baseURL}auth/authUser/all/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.pageMain {
  margin-top: 40px;
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

::v-deep .el-input {
  width: 200px;
  height: 32px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
</style>
